
























































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineELN4FSSafety1',
  components: {TableInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        rows: [
          {
            chemicalName: '',
            GhsSymbol1: '',
            precautionaryStatement1: '',
            GhsSymbol2: '',
            precautionaryStatement2: '',
          },
        ],
        safetyMoment: '',
      },
      attachments: [] as File[],
      columns: [
        {label: 'Chemical Name', component: STextarea, prop: 'chemicalName', width: '30%'},
        {label: 'GHS Symbol #1', component: STextarea, prop: 'GhsSymbol1', width: '10%'},
        {
          label: 'Associated Precautionary Statement',
          component: STextarea,
          prop: 'precautionaryStatement1',
          width: '25%',
        },
        {label: 'GHS Symbol #2', component: STextarea, prop: 'GhsSymbol2', width: '10%'},
        {
          label: 'Associated Precautionary Statement',
          component: STextarea,
          prop: 'precautionaryStatement2',
          width: '25%',
        },
      ],
    };
  },
  methods: {},
  computed: {
    imageName() {
      return '/img/assignments/GHS_Symbols.png';
    },
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
